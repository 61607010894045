import { MasterHoldMaintenanceConstant } from "presentation/constant/MasterHold/MasterHoldMaintenanceConstant";
import { useMasterHoldMaintenanceTracked } from "presentation/store/MasterHold/MasterHoldMaintenanceProvider";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { memo } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const MasterHoldTitleBar: React.FC = () => {
    const [masterHoldState] = useMasterHoldMaintenanceTracked();
    const MASTER_HOLD_CONSTANT = MasterHoldMaintenanceConstant.Header;

    return <Sidebarheader style={{ width: '100%', display: "flex", alignItems: "center" }}>
        <HeaderTitle>{MASTER_HOLD_CONSTANT.TITLE}</HeaderTitle>
        {(masterHoldState.isLoading) && <Loader Indicator="Spinner" size="Medium" />}
    </Sidebarheader>
}

export default memo(MasterHoldTitleBar);