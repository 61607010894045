import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";

import { useMasterHoldMaintenanceTracked } from "presentation/store/MasterHold/MasterHoldMaintenanceProvider";
import MasterHoldDetailTablePanel from "./Table/MasterHoldDetailTablePanel";
import { useMasterHoldMaintenanceVM } from "presentation/hook/MasterHold/useMasterHoldMaintenanceVM";

export const TariffCodeEditPanel: React.FC = () => {
    const [masterHoldState] = useMasterHoldMaintenanceTracked();
    const masterHoldVM = useMasterHoldMaintenanceVM();
    const { isShowDetail } = masterHoldState;
    const { isSliderOpen } = masterHoldState.detailState;
    const masterHoldEty = masterHoldState.currentSelectedRow;

    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
        if(isShowDetail){
            const initialScreen = async() => {   
                setIsLoading(true);
                try {     
                    const results = await Promise.allSettled([
                        masterHoldEty?.id && masterHoldVM.searchChargeInfo(masterHoldEty.id)
                    ]);
                    results.forEach((result, index) => {
                        if (index === 0 && result.status === 'fulfilled') {
    
                        } 
                    })
                } catch(error) {
                    setIsLoading(false);
                }
            }
            initialScreen().then(async () => {
                setIsLoading(false)
            }).catch(error => {            
                setIsLoading(false)
            });
        }
    }, [isShowDetail, masterHoldEty.id, masterHoldVM])

    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    
        return <div className='main-comp-wrapper'>
                <SliderPanel
                    isOpen={isSliderOpen}
                    leftSectionWidth={'100%'}
                    rightSectionWidth={'0%'}
                    draggable={true}
                    leftChildren={<MasterHoldDetailTablePanel/>}
                    />
            </div>
    }
    
export default memo(TariffCodeEditPanel);