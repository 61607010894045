import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { MasterHoldMaintenanceProvider } from "presentation/store/MasterHold/MasterHoldMaintenanceProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import { MasterHoldMasterView } from "presentation/view/section/MasterHold/MasterHoldMasterView";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const MasterHoldMaintenanceCont = () => {
    return <ANAInfoWrapper permission={Permission.MASTER_HOLD_MAINTENANCE}>
        <MessageBarWrapper>
            <MasterHoldMaintenanceProvider>
                <GridStyles/>
                <MasterHoldMasterView/>
            </MasterHoldMaintenanceProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default MasterHoldMaintenanceCont;