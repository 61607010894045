import _ from "lodash";
import { MasterHoldMaintenanceConstant } from "presentation/constant/MasterHold/MasterHoldMaintenanceConstant";
import { useMasterHoldMaintenanceVM } from "presentation/hook/MasterHold/useMasterHoldMaintenanceVM";
import { useMasterHoldMaintenanceTracked } from "presentation/store/MasterHold/MasterHoldMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DateRangePickerComponent from "presentation/view/components/DateRangePickerComponent";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { useEffect, useMemo, useState } from "react";
import { FieldType, HPHCheckbox, IFieldValue } from "veronica-ui-component/dist/component/core";
import { SidebarCaption, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import MasterHoldEditPanelTitleBar from "../MasterHoldEditPanelTitleBar";

export const MasterHoldHeaderFormPanel = () => {
    const [masterHoldState] = useMasterHoldMaintenanceTracked();
    const masterHoldVM = useMasterHoldMaintenanceVM();
    const { currentSelectedRow, masterState, dynamicOptions } = masterHoldState;
    const { editingEntity, isAdd, isEditable, isRead, isSaveClicked, allFormState } = masterState;

    const SCREEN_CONSTANT = MasterHoldMaintenanceConstant.Header;
    const [, setIsLoading] = useState(false);
    const currentEntity = isRead ? currentSelectedRow : editingEntity;

    const memoSubChgTypeOptions = useMemo(() => {
        const groupOfSubChgTypeOptions = masterHoldState.dynamicOptions.subChargeTypeDropdownOptions[currentEntity.chargeType ?? ""];
        const commonSubChgTypeOptions = _.orderBy(_.uniqBy(_.flatMap(_.concat(groupOfSubChgTypeOptions ?? [])), 'value'), 'dropdownLabel');
        return commonSubChgTypeOptions;
    }, [currentEntity.chargeType, masterHoldState.dynamicOptions.subChargeTypeDropdownOptions]);

    useEffect(() => {
        if (isAdd || isEditable) {
            const initialScreen = async () => {
                setIsLoading(true);
                try {
                    const results = await Promise.allSettled([
                        masterHoldVM.loadDropdownOption().then(data => {
                            masterHoldVM.onInitDefaultValue();
                        })
                    ]);
                    results.forEach((result, index) => {
                        if (index === 0 && result.status === 'fulfilled') {

                        }
                    })
                } catch (error) {
                    setIsLoading(false);
                }
            }
            initialScreen().then(async () => {
                setIsLoading(false)
            }).catch(error => {
                setIsLoading(false)
            });
        }

    }, [isAdd, isEditable, masterHoldVM])



    const memoTariffCodeOptions = useMemo(() => {
        return currentEntity.tariffType
            ? masterHoldState.dynamicOptions.tariffCodeDropdownOptions[currentEntity.tariffType]
            : [];
    }, [currentEntity.tariffType, masterHoldState.dynamicOptions.tariffCodeDropdownOptions]);

    const memoMasterChgOnComp = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                disableInput={true}
                readOnlyValue={currentEntity?.masterChargeOnCompanyCode ?? ''}
                fieldValue={currentEntity?.masterChargeOnCompanyCode ?? ''}
                fieldLabel={SCREEN_CONSTANT.MASTER_CHARGE_ON_COMPANY}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'masterChargeOnCompanyCode'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.masterCompanyCodeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    masterHoldVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.masterChargeOnCompanyCode, SCREEN_CONSTANT.MASTER_CHARGE_ON_COMPANY, isSaveClicked, dynamicOptions.masterCompanyCodeDropdownOptions, masterHoldVM])

    const memoChargeOnCompany = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                disableInput={true}
                readOnlyValue={currentEntity?.chargeOnCompanyCode ?? ''}
                fieldValue={currentEntity?.chargeOnCompanyCode ?? ''}
                fieldLabel={SCREEN_CONSTANT.CHARGE_ON_COMPANY}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeOnCompanyCode'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.companyCodeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    masterHoldVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.chargeOnCompanyCode, SCREEN_CONSTANT.CHARGE_ON_COMPANY, isSaveClicked, dynamicOptions.companyCodeDropdownOptions, masterHoldVM])

    const memoMasterBillToComp = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                disableInput={true}
                readOnlyValue={currentEntity?.masterBillToCompanyCode ?? ''}
                fieldValue={currentEntity?.masterBillToCompanyCode ?? ''}
                fieldLabel={SCREEN_CONSTANT.MASTER_BILL_TO_COMPANY}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'masterBillToCompanyCode'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.masterCompanyCodeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    masterHoldVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.masterBillToCompanyCode, SCREEN_CONSTANT.MASTER_BILL_TO_COMPANY, isSaveClicked, dynamicOptions.masterCompanyCodeDropdownOptions, masterHoldVM])

    const memoBillToCompany = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                disableInput={true}
                readOnlyValue={currentEntity?.billToCompanyCode ?? ''}
                fieldValue={currentEntity?.billToCompanyCode ?? ''}
                fieldLabel={SCREEN_CONSTANT.BILL_TO_COMPANY}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'billToCompanyCode'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.companyCodeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    masterHoldVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.billToCompanyCode, SCREEN_CONSTANT.BILL_TO_COMPANY, isSaveClicked, dynamicOptions.companyCodeDropdownOptions, masterHoldVM])

    const memoChargeType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                disableInput={true}
                readOnlyValue={currentEntity?.chargeType ?? ''}
                fieldValue={currentEntity?.chargeType ?? ''}
                fieldLabel={SCREEN_CONSTANT.CHARGE_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeType'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.chargeTypeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    masterHoldVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.chargeType, SCREEN_CONSTANT.CHARGE_TYPE, isSaveClicked, dynamicOptions.chargeTypeDropdownOptions, masterHoldVM])

    const memoSubChargeType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                disableInput={true}
                readOnlyValue={currentEntity?.subChargeType ?? ''}
                fieldValue={currentEntity?.subChargeType ?? ''}
                fieldLabel={SCREEN_CONSTANT.SUB_CHARGE_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'subChargeType'}
                isShowMissingError={true}
                maxLength={10}
                options={memoSubChgTypeOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    masterHoldVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.subChargeType, SCREEN_CONSTANT.SUB_CHARGE_TYPE, isSaveClicked, memoSubChgTypeOptions, masterHoldVM])

    const memoTarType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                disableInput={true}
                readOnlyValue={currentEntity?.tariffType ?? ''}
                fieldValue={currentEntity?.tariffType ?? ''}
                fieldLabel={SCREEN_CONSTANT.TAR_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffType'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.tariffTypeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    masterHoldVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.tariffType, SCREEN_CONSTANT.TAR_TYPE, isSaveClicked, dynamicOptions.tariffTypeDropdownOptions, masterHoldVM])

    const memoTarCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                disableInput={true}
                readOnlyValue={currentEntity?.tariffCode ?? ''}
                fieldValue={currentEntity?.tariffCode ?? ''}
                fieldLabel={SCREEN_CONSTANT.TAR_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffCode'}
                isShowMissingError={true}
                maxLength={10}
                options={memoTariffCodeOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    masterHoldVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.tariffCode, SCREEN_CONSTANT.TAR_CODE, isSaveClicked, memoTariffCodeOptions, masterHoldVM])

    const memoShipmentType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                disableInput={true}
                readOnlyValue={currentEntity?.shipmentType ?? ''}
                fieldValue={currentEntity?.shipmentType ?? ''}
                fieldLabel={SCREEN_CONSTANT.SHIPMENT_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'shipmentType'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.shipmentTypeDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    masterHoldVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.shipmentType, SCREEN_CONSTANT.SHIPMENT_TYPE, isSaveClicked, dynamicOptions.shipmentTypeDropdownOptions, masterHoldVM])

    const memoOpsDateRange = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "390px", marginBottom: "24px" }}>
            <DateRangePickerComponent
                label={SCREEN_CONSTANT.OPS_DATE_RANGE}
                disabled={isRead}
                width='390px'
                fieldNames={{ startField: "effectiveDate", endField: "expiryDate" }}
                errorMessage={allFormState?.effectiveDate}
                dateRange={{ startDate: currentEntity.effectiveDate, endDate: currentEntity.expiryDate }}
                onDatesChange={masterHoldVM.onDateRangeChange}
            />
        </div>
        , [SCREEN_CONSTANT.OPS_DATE_RANGE, allFormState?.effectiveDate, currentEntity.effectiveDate, currentEntity.expiryDate, isRead, masterHoldVM.onDateRangeChange])

    const memoTargetState = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={false}
                disableInput={true}
                readOnlyValue={currentEntity?.target ?? ''}
                fieldValue={currentEntity?.target ?? ''}
                fieldLabel={SCREEN_CONSTANT.TARGET_STATE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'target'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.targetStateDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    masterHoldVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.target, SCREEN_CONSTANT.TARGET_STATE, isSaveClicked, dynamicOptions.targetStateDropdownOptions, masterHoldVM])

    const memoCntrStatus = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                disableInput={true}
                readOnlyValue={currentEntity?.cntrStatus ?? ''}
                fieldValue={currentEntity?.cntrStatus ?? ''}
                fieldLabel={SCREEN_CONSTANT.CNTR_STATUS + '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'cntrStatus'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.cntrStatusDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    masterHoldVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.cntrStatus, SCREEN_CONSTANT.CNTR_STATUS, isSaveClicked, dynamicOptions.cntrStatusDropdownOptions, masterHoldVM])

    const memoModalityOfArr = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                disableInput={true}
                readOnlyValue={currentEntity?.modalityOfArrival ?? ''}
                fieldValue={currentEntity?.modalityOfArrival ?? ''}
                fieldLabel={SCREEN_CONSTANT.MODALITY_OF_ARRIVAL}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'modalityOfArrival'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.modalityDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    masterHoldVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.modalityOfArrival, SCREEN_CONSTANT.MODALITY_OF_ARRIVAL, isSaveClicked, dynamicOptions.modalityDropdownOptions, masterHoldVM])

    const memoModalityOfDep = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                disableInput={true}
                readOnlyValue={currentEntity?.modalityOfDeparture ?? ''}
                fieldValue={currentEntity?.modalityOfDeparture ?? ''}
                fieldLabel={SCREEN_CONSTANT.MODALITY_OF_DEPARTURE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'modalityOfDeparture'}
                isShowMissingError={true}
                maxLength={10}
                options={dynamicOptions.modalityDropdownOptions}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    masterHoldVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.modalityOfDeparture, SCREEN_CONSTANT.MODALITY_OF_DEPARTURE, isSaveClicked, dynamicOptions.modalityDropdownOptions, masterHoldVM])

    const memoEndVisitInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "180px", marginBottom: "24px" }}>
            <NbisCommonField
                fieldKey={"activeInd"}
                requiredFieldList={[]}
                fieldLabel={SCREEN_CONSTANT.END_VISIT_IND}
                fieldType={FieldType.TEXT}
                isReadOnly={true}
                onFieldChange={() => { }} />
            <HPHCheckbox
                errorMessages={allFormState}
                label={''}
                checked={currentEntity?.endVisitInd === 'Y' ? true : false}
                disabled={isRead}
                onChange={(e) => masterHoldVM.onCheckboxChange(e.checked, "endVisitInd")} />
        </div>
        , [SCREEN_CONSTANT.END_VISIT_IND, allFormState, currentEntity?.endVisitInd, isRead, masterHoldVM])

    const memoEditPanelTitleBar = useMemo(() => {
        return <MasterHoldEditPanelTitleBar />
    }, [])


    return <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
        {/* Header part of add or edit record */}
        {memoEditPanelTitleBar}

        {/* Readable and Editable Form */}
        <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}>
            <div className="flex-row-auto">
                <div className={'flex-row-item'}>
                    <div className={'im-flex-row'}>
                        <div className={'flex-row-item-stretch'}>
                            {/*<SidebarTitle>{!!currentEntity?.tariffCode ? currentEntity?.tariffCode : "NEW TARIFF CODE"}</SidebarTitle>*/}

                            {(isAdd || (isEditable && !isRead)) && <SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                        </div>
                    </div>

                    <CriteriaItemContainer>
                        {memoMasterChgOnComp}
                        {memoChargeOnCompany}
                        {memoMasterBillToComp}
                    </CriteriaItemContainer>

                    <CriteriaItemContainer>
                        {memoBillToCompany}
                        {memoChargeType}
                        {memoSubChargeType}
                    </CriteriaItemContainer>

                    <CriteriaItemContainer>
                        {memoTarType}
                        {memoTarCode}
                        {memoShipmentType}
                    </CriteriaItemContainer>

                    <CriteriaItemContainer>
                        {memoOpsDateRange}
                        {memoTargetState}
                    </CriteriaItemContainer>

                    <CriteriaItemContainer>
                        {memoCntrStatus}
                        {memoModalityOfArr}
                        {memoModalityOfDep}
                    </CriteriaItemContainer>

                    <CriteriaItemContainer>
                        {memoEndVisitInd}
                    </CriteriaItemContainer>

                </div>
            </div>
        </div>
        {/* Readable and Editable Form */}
    </StyledSidebar>
}
