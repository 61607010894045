export const masterHoldMaintenanceRequiredFieldList: string[] = [
    'masterHold',
];

export const MasterHoldMaintenanceConstant = {
    CATEGORY: "Invoice",
    Header: {
        TITLE: "Master Hold",
        PRIORITY: "Priority",
        MASTER_CHARGE_ON_COMPANY: "Master Charge On Company",
        CHARGE_ON_COMPANY: "Charge On Company",
        MASTER_BILL_TO_COMPANY: "Master Bill To Company",
        BILL_TO_COMPANY: "Bill To Company",
        CHARGE_TYPE: "Charge Type",
        SUB_CHARGE_TYPE: "Sub Charge Type",
        TAR_TYPE: "Tar Type",
        TAR_CODE: "Tar Code",
        SHIPMENT_TYPE: "Shipment Type",
        OPS_DATE_RANGE: "Ops Date Range",
        OPS_DATE_FROM: "Ops Date From",
        OPS_DATE_TO: "Ops Date To",
        TARGET_STATE: "Target State",
        CNTR_STATUS: "Cntr Status",
        MODALITY_OF_ARRIVAL: "Modality of Arrival",
        MODALITY_OF_DEPARTURE: "Modality of Departure",
        END_VISIT_IND: 'End Visit Ind.'
    },

    Detail: {
        CHARGE_INFO: "Charge Information",
        STATE: "State",
        CHARGE_TYPE: "Charge Type",
        SUB_CHARGE_TYPE: "Sub Charge Type",
        TARIFF_TYPE: "Tariff Type",
        TARIFF_CODE: "Tariff Code",
        CHARGE_ON_COMPANY: "Charge-on Company",
        BILL_TO_COMPANY: "Bill-to Company",
        CONSORTIUM_CODE: "Consortium Code",
        VESSEL_CODE: "Vessel Code",
        VOYAGE_CODE: "Voyage Code",
        ETD: "ETD",
        OPS_DATE: "Ops Date",
        CNTR_NO: "Container No.",
        SIZE: "Size",
        STATUS: "Status"
    }
}
