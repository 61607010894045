import { ChargeConstantCollection } from "constants/charge/ChargeConstantCollection";

export const MasterHoldTargetDroOpts = () => {

    const getTargetModel = () => {
        let items = [ChargeConstantCollection.targetState.MH, ChargeConstantCollection.targetState.MT];

        return [
            ...(items?.map(key => ({
                dropdownLabel: key,
                tagLabel: key,
                value: key
            })) ?? [])];
    }

    return {
        getTargetModel
    }
}