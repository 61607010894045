import { EMPTY_MASTER_HOLD_MODEL } from "presentation/model/MasterHold/MasterHoldMaintenanceModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: MasterHoldMaintenanceProvider,
    useTracked: useMasterHoldMaintenanceTracked
} = createContainer(() => useState(EMPTY_MASTER_HOLD_MODEL), {concurrentMode: true});
export { MasterHoldMaintenanceProvider, useMasterHoldMaintenanceTracked };

