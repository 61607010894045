import * as yup from 'yup';


export const commonMasterHoldMaintenanceValidation: { [x: string]: any; } = {
  target: yup.string().required("Missing"),
  //masterHoldCode: yup.string().required("Missing"),
  effectiveDate: yup.date().required("Missing"),
  //toDate: yup.date().required("Missing"),
};

export const masterHoldMaintenanceValidation: { [x: string]: any; } = {
  ...commonMasterHoldMaintenanceValidation,
};

export const MasterHoldMaintenanceValidationSchema = yup.object().shape(masterHoldMaintenanceValidation);

