import { MasterDataType } from "domain/entity/MasterData/MasterDataEntity";
import { MasterHoldChargeInfoEntity } from "domain/entity/MasterHold/MasterHoldChargeInfoEntity";
import { EMPTY_MASTER_HOLD_ENTITY, MasterHoldEntity } from "domain/entity/MasterHold/MasterHoldEntity";
import { ChargeTypeRepository } from "domain/repository/ChargeType/ChargeTypeRepo";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { MasterDataRepository } from "domain/repository/MasterData/MasterDataRepo";
import { MasterHoldRepository } from "domain/repository/MasterHold/MasterHoldRepo";
import { ParameterDetailRepository } from "domain/repository/parameter/ParameterDetailRepo";
import { StandardTariffCodeRepository } from "domain/repository/TariffCode/StandardTariffCodeRepo";
import { TariffTypeRepository } from "domain/repository/TariffCode/TariffTypeRepo";
import _ from "lodash";
import { CntrStatusDroOpts } from "presentation/constant/DropDownOptions/Company/CntrStatusDroOpts";
import { MasterHoldTargetDroOpts } from "presentation/constant/DropDownOptions/Document/MasterHoldTargetDroOpts";
import { endVisitIndDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { MasterHoldMaintenanceValidationSchema } from "presentation/constant/MasterHold/MasterHoldMaintenanceValidationSchema";
import { Validation } from "presentation/constant/Validation";
import { DropdownProps } from "presentation/model/DropdownProps";
import { MasterHoldMaintenanceModel } from "presentation/model/MasterHold/MasterHoldMaintenanceModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";
import { ParameterCodeValue } from "presentation/constant/Parameter/ParameterCodeValue";

interface MasterHoldMaintenanceVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<MasterHoldMaintenanceModel>> | ((value: SetStateAction<MasterHoldMaintenanceModel>) => void),
    ],
    repo: MasterHoldRepository,
    companyRepo: CompanyRepository,
    chargeTypeRepo: ChargeTypeRepository,
    standardTariffCodeRepo: StandardTariffCodeRepository,
    parameterDetailRepo: ParameterDetailRepository,
    masterDataRepo: MasterDataRepository,
    tariffTypeRepo: TariffTypeRepository,
}

export const MasterHoldMaintenanceVM = ({ dispatch, repo, companyRepo, chargeTypeRepo, standardTariffCodeRepo, parameterDetailRepo, masterDataRepo, tariffTypeRepo }: MasterHoldMaintenanceVMProps) => {
    const [masterHoldMainDispatch] = dispatch;

    const onShowLoading = () => {
        masterHoldMainDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true,
            }
        })
    }

    const onHideLoading = () => {
        masterHoldMainDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false,
            }
        })
    }

    const updateSelectedRows = async (rows: MasterHoldEntity[]) => {
        masterHoldMainDispatch(prevState => {
            return {
                ...prevState,
                //tableData: [...allRows],
                selectedRows: rows,
            }
        })
    }

    const onApply = async (rows: MasterHoldEntity[]) => {
        return await repo.applyEntity(rows);
    }

    const updateSelectedChgInfoRows = async (allRows: MasterHoldChargeInfoEntity[], rows: MasterHoldChargeInfoEntity[]) => {

        masterHoldMainDispatch(prevState => {
            return {
                ...prevState,
                detailState: {
                    ...prevState.detailState,
                    masterHoldChgInfoList: [...allRows],
                    selectedRows: rows
                }
            }
        })
    }

    const onRowDoubleClick = (entity: MasterHoldEntity) => {
        masterHoldMainDispatch(prevState => {
            return {
                ...prevState,
                isShowCriteriaPanel: false,
                isShowDetail: true,
                currentSelectedRow: entity,

                masterState: {
                    ...prevState.masterState,
                    isTabularDataActive: false,
                    isAdd: false,
                    isRead: true,
                    isEditable: true,
                    isSaveClicked: false,
                    isSliderOpen: true,
                    allFormState: {}
                },
                detailState: {
                    ...prevState.detailState,
                    isTabularDataActive: false,
                    isAdd: false,
                    isRead: true,
                    isEditable: false,
                    isSliderOpen: false,
                    isSelected: false,
                    allFormState: {}
                }
            }
        })
    }

    const loadDropdownOption = async () => {

        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let masterCompanies = companies?.filter(company => company.companyType === 'MASTER');

                let masterCompanyDropdownOptions = masterCompanies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.id.toString(),
                    value: company.companyCode,
                })) ?? []
                masterCompanyDropdownOptions = _.orderBy(masterCompanyDropdownOptions, "dropdownLabel");

                let companyDropdownOptions = companies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.id.toString(),
                    value: company.companyCode,
                })) ?? []
                companyDropdownOptions = _.orderBy(companyDropdownOptions, "dropdownLabel");

                masterHoldMainDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        masterCompanyCodeDropdownOptions: masterCompanyDropdownOptions,
                        companyCodeDropdownOptions: companyDropdownOptions
                    }
                }))
            }
        );

        await chargeTypeRepo.getAllChargeTypesForCombobox().then(
            chargeTypes => {
                let newChargeTypes = _.orderBy(chargeTypes, ["chargeType", "subChargeType"]);
                let chargeTypeDropdownOptions: DropdownProps[] = [];
                let subChargeTypeDropdownOptions: { [key: string]: DropdownProps[] } = {};
                newChargeTypes.forEach(chgTypeEty => {
                    const chgTypeExisted = chargeTypeDropdownOptions.find(chgType =>
                        chgType.value === chgTypeEty.chargeType);
                    if (!chgTypeExisted) {
                        chargeTypeDropdownOptions.push({
                            dropdownLabel: chgTypeEty.chargeType,
                            tagLabel: chgTypeEty.chargeType,
                            value: chgTypeEty.chargeType
                        })
                    }

                    if (chgTypeEty.subChargeType) {
                        if (!subChargeTypeDropdownOptions[chgTypeEty.chargeType]) {
                            subChargeTypeDropdownOptions[chgTypeEty.chargeType] = [];
                        }
                        subChargeTypeDropdownOptions[chgTypeEty.chargeType].push({
                            dropdownLabel: chgTypeEty.subChargeType,
                            tagLabel: chgTypeEty.subChargeType,
                            value: chgTypeEty.subChargeType
                        });
                    }
                });

                masterHoldMainDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chargeTypeDropdownOptions: chargeTypeDropdownOptions,
                        subChargeTypeDropdownOptions: subChargeTypeDropdownOptions
                    }
                }))
            }
        )

        await tariffTypeRepo.getAllActiveTariffTypes().then(
            tariffs => {
                let newTariffs = _.orderBy(tariffs, ["tariffType"]);
                let tariffTypeDropdownOption = newTariffs?.map((tariff) => ({
                    dropdownLabel: tariff.tariffType,
                    tagLabel: tariff.tariffType,
                    value: tariff.tariffType,
                })) ?? []
                masterHoldMainDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        tariffTypeDropdownOptions: tariffTypeDropdownOption,
                    }
                }))
            }
        );

        await standardTariffCodeRepo.getAllStandardTariffCodes().then(
            tariffs => {

                let newTariffs = _.orderBy(tariffs, ["tariffType", "tariffCode"]);
                let tariffCodeDropdownOptions: { [key: string]: DropdownProps[] } = {};

                newTariffs.forEach(tariff => {

                    if (tariff.tariffCode) {
                        if (!tariffCodeDropdownOptions[tariff.tariffType]) {
                            tariffCodeDropdownOptions[tariff.tariffType] = [];
                        }
                        tariffCodeDropdownOptions[tariff.tariffType].push({
                            dropdownLabel: tariff.tariffCode,
                            tagLabel: tariff.tariffCode,
                            value: tariff.tariffCode,
                        })

                    }
                })

                masterHoldMainDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        tariffCodeDropdownOptions: tariffCodeDropdownOptions,
                    }
                }))
            }
        )

        await parameterDetailRepo.getAllParameterDtlsByParameterCode(ParameterCodeValue.SHIPMENT_TYPE).then(
            shipmentTypes => {
                const shipmentTypeDropdownOptions = shipmentTypes?.map((shipmentType) => ({
                    dropdownLabel: shipmentType.parameterDtlCode,
                    tagLabel: shipmentType.parameterDtlCode,
                    value: shipmentType.parameterDtlCode,
                })) ?? []

                masterHoldMainDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        shipmentTypeDropdownOptions: shipmentTypeDropdownOptions,
                    }
                }))
            }
        )

        await masterDataRepo.getMasterDataByKey(MasterDataType.MODALITY).then(
            modalities => {
                const modalityDropdownOptions = modalities?.map((modality) => ({
                    dropdownLabel: modality.code,
                    tagLabel: modality.code,
                    value: modality.code,
                })) ?? []

                masterHoldMainDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        modalityDropdownOptions: modalityDropdownOptions
                    }
                }))
            }
        )

        masterHoldMainDispatch(prevState => ({
            ...prevState,
            dynamicOptions: {
                ...prevState.dynamicOptions,
                cntrStatusDropdownOptions: CntrStatusDroOpts().getDroOptsModel(),
                endVisitIdDropdownOptions: endVisitIndDropdownOption,
                targetStateDropdownOptions: MasterHoldTargetDroOpts().getTargetModel()
            }
        }))
    }

    const onInitDefaultValue = async () => {
    }

    const searchMasterHold = async () => {
        await repo.getEntities().then((data) => {
            masterHoldMainDispatch(prevState => {
                return {
                    ...prevState,
                    tableData: data,
                    selectedRows: [],
                    currentSelectedRow: EMPTY_MASTER_HOLD_ENTITY,
                    isBackMaster: false,
                }
            })
        }).catch((error) => {
            return [];
        })
    }

    const searchChargeInfo = async (masterHoldId: number) => {
        await repo.searchChargeInfo(masterHoldId).then((data) => {
            return masterHoldMainDispatch(prevState => ({
                ...prevState,
                detailState: {
                    ...prevState.detailState,
                    masterHoldChgInfoList: data
                }
            }))
        }).catch((error) => {
            return [];
        })
    }

    const onFieldChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        return masterHoldMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingEntity: {
                        ...prevState.masterState.editingEntity,
                        [fieldKey]: val
                    }
                }
            }
        });
    }

    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        masterHoldMainDispatch(prevState => ({
            ...prevState,
            masterState: {
                ...prevState.masterState,
                editingEntity: {
                    ...prevState.masterState.editingEntity,
                    [fieldName]: checked ? "Y" : "N"
                }
            }
        }))
    }

    const onDateRangeChange = (startDate: any, endDate: any, dateFields: { startField: string, endField: string }) => {
        masterHoldMainDispatch(prevState => ({
            ...prevState,
            masterState: {
                ...prevState.masterState,
                editingEntity: {
                    ...prevState.masterState.editingEntity,
                    [dateFields.startField]: startDate,
                    [dateFields.endField]: endDate
                }
            }
        }))
    }

    const onResetClick = async () => {
        masterHoldMainDispatch(prevState => {
            let tempMasterHoldEty: MasterHoldEntity = EMPTY_MASTER_HOLD_ENTITY;
            if (!prevState.masterState.isAdd) {
                tempMasterHoldEty = prevState.currentSelectedRow
            }

            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingEntity: {
                        ...tempMasterHoldEty,
                    },
                }
            }
        })
    }

    const onAddClick = () => {
        masterHoldMainDispatch(prevState => ({
            ...prevState,
            //isShowDetail: true,
            currentSelectedRow: EMPTY_MASTER_HOLD_ENTITY,
            masterState: {
                ...prevState.masterState,
                isTabularDataActive: false,
                isAdd: true,
                isRead: false,
                isEditable: false,
                isSaveClicked: false,
                isSliderOpen: true,
                allFormState: {},
                editingEntity: EMPTY_MASTER_HOLD_ENTITY,
            },
            detailState: {
                ...prevState.detailState,
                isTabularDataActive: false,
                isAdd: false,
                isRead: true,
                isEditable: false,
                isSliderOpen: false,
                isSelected: false,
                allFormState: {}
            }
        }))
    };

    const onDeleteClick = () => {
        masterHoldMainDispatch(prevState => {
            return {
                ...prevState,
                isShowDeleteModal: true
            }
        });
    }

    const onDelete = async (selectedRows: MasterHoldEntity[]) => {
        await repo.deleteEntity(selectedRows).then((res) => {
            masterHoldMainDispatch(prevState => {
                return {
                    ...prevState,
                    isShowRightPanel: false,
                    currentSelectedRow: EMPTY_MASTER_HOLD_ENTITY,
                    selectedRows: [],
                    isShowDeleteModal: false,

                    masterState: {
                        isSliderOpen: false,
                        isTabularDataActive: false,
                        isAdd: false,
                        isRead: false,
                        isEditable: false,
                        isSaveClicked: false,
                        lastEditRowId: "",
                        isSelected: false,
                        slider: {
                            isAdd: false,
                            isRead: false,
                            isEditable: false
                        },
                        editingEntity: EMPTY_MASTER_HOLD_ENTITY
                    }
                }
            });
        })
    }

    const onRelease = async (selectedRows: MasterHoldChargeInfoEntity[]) => {
        /*await repo.releaseChargeInfo(selectedRows).then((res) => {
            masterHoldMainDispatch(prevState => {
                return {
                    ...prevState,                    
                    detailState: {
                        ...prevState.detailState,
                        masterHoldChgInfoList: [],                        
                        selectedRows: []
                    }
                }
            });
        })    */

        let res = await repo.releaseChargeInfo(selectedRows);

        if (res.success) {
            onCloseClick();
        } else {
            return res.data;
        }
    }

    const onHold = async (selectedRows: MasterHoldChargeInfoEntity[]) => {
        /*await repo.holdChargeInfo(selectedRows).then((res) => {
            masterHoldMainDispatch(prevState => {
                return {
                    ...prevState,                    
                    detailState: {
                        ...prevState.detailState, 
                        masterHoldChgInfoList: [],                      
                        selectedRows: []
                    }
                }
            });
        }) */
        let res = await repo.holdChargeInfo(selectedRows);

        if (res.success) {
            onCloseClick();
        } else {
            return res.data;
        }
    }

    const onCancelDelete = () => {
        masterHoldMainDispatch(prevState => {
            return {
                ...prevState,
                isShowDeleteModal: false
            }
        });
    }

    const onSave = async (currentMasterHoldEty: MasterHoldEntity, isAdd: boolean) => {
        const valHdrResult = await Validation(MasterHoldMaintenanceValidationSchema).ValidateFormOnly(currentMasterHoldEty);

        if (valHdrResult) {
            let validatedResult: { [x: string]: string } = {};
            if (valHdrResult) {
                validatedResult = { ...validatedResult, ...valHdrResult, mandatoryCheckFail: 'Please input the missing value.' };
            }

            masterHoldMainDispatch(prevState => {
                return {
                    ...prevState,
                    masterState: {
                        ...prevState.masterState,
                        allFormState: {
                            ...validatedResult
                        },

                    }
                }
            });
            return validatedResult;
        } else {

            let res = null;
            if (isAdd) {
                res = await repo.createEntity(currentMasterHoldEty);
            } else {
                res = await repo.updateEntity(currentMasterHoldEty);
            }

            if (res.success) {
                onCloseClick();
                return { success: true };
            } else {
                return { error: res.data };
            }
        }
    }

    const onCloseClick = () => {
        masterHoldMainDispatch(prevState => {
            return {
                ...prevState,
                isShowDetail: false,
                isBackMaster: true,
                selectedRows: [],
                currentSelectedRow: { ...EMPTY_MASTER_HOLD_ENTITY },
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: false,
                    isRead: true,
                    isTabularDataActive: true,
                    editingEntity: EMPTY_MASTER_HOLD_ENTITY,
                },
                detailState: {
                    ...prevState.detailState,
                    isAdd: false,
                    isEditable: false,
                    isRead: true,
                    isTabularDataActive: true,
                    masterHoldChgInfoList: [],
                    selectedRows: []
                }
            }
        });
    }

    const onSaveClicked = () => {
        masterHoldMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }


    const onEdit = (currentMasterHoldEty: MasterHoldEntity) => {
        masterHoldMainDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isRead: false,
                    isEditable: true,

                    editingEntity: {
                        ...currentMasterHoldEty,
                    }
                },
            }
        })
    }

    return {
        loadDropdownOption: loadDropdownOption,
        onShowLoading: onShowLoading,
        onHideLoading: onHideLoading,
        onRowDoubleClick: onRowDoubleClick,
        onFieldChange: onFieldChange,
        searchMasterHold: searchMasterHold,
        searchChargeInfo: searchChargeInfo,
        updateSelectedRows: updateSelectedRows,
        updateSelectedChgInfoRows: updateSelectedChgInfoRows,
        onAddClick: onAddClick,
        onResetClick: onResetClick,
        onSave: onSave,
        onCloseClick: onCloseClick,
        onSaveClicked: onSaveClicked,
        onDeleteClick: onDeleteClick,
        onCancelDelete: onCancelDelete,
        onDelete: onDelete,
        onEdit: onEdit,
        onInitDefaultValue: onInitDefaultValue,
        onCheckboxChange: onCheckboxChange,
        onDateRangeChange: onDateRangeChange,
        onHold: onHold,
        onRelease: onRelease,
        onApply: onApply
    }
}