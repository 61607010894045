import { useMasterHoldMaintenanceTracked } from "presentation/store/MasterHold/MasterHoldMaintenanceProvider";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import MasterHoldMaintenance from "./MasterHoldMaintenance";
import { MasterHoldHeaderFormPanel } from "./Form/MasterHoldHeaderFormPanel";
import MasterHoldEditPanel from "./MasterHoldEditPanel";

export const MasterHoldMasterView: React.FC = () => {
    const [masterHoldState] = useMasterHoldMaintenanceTracked();
    const { isAdd, isEditable, isSliderOpen } = masterHoldState.masterState;

    return <>
        <div className="main-comp-wrapper">            
            <MasterHoldMaintenance/>            
            {
                ((isAdd || isEditable) && 
                <SliderPanel
                    isOpen={isSliderOpen}
                    leftSectionWidth={"36%"}
                    rightSectionWidth={"64%"}
                    leftChildren={<MasterHoldHeaderFormPanel/>}
                    rightChildren={<MasterHoldEditPanel/>}
                />
                )
            }
        </div>
    </>
}