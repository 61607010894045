
import { MasterHoldEntity, EMPTY_MASTER_HOLD_ENTITY } from "domain/entity/MasterHold/MasterHoldEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";
import { MasterHoldChargeInfoEntity } from "domain/entity/MasterHold/MasterHoldChargeInfoEntity";

export interface MasterHoldDropdownOptions {
    masterCompanyCodeDropdownOptions: DropdownProps[],
    companyCodeDropdownOptions: DropdownProps[],
    chargeTypeDropdownOptions: DropdownProps[],
    subChargeTypeDropdownOptions: { [key: string]: DropdownProps[] },
    tariffTypeDropdownOptions: DropdownProps[],
    tariffCodeDropdownOptions: { [key: string]: DropdownProps[] },
    shipmentTypeDropdownOptions: DropdownProps[],
    targetStateDropdownOptions: DropdownProps[],
    cntrStatusDropdownOptions: DropdownProps[],
    modalityDropdownOptions: DropdownProps[],
    endVisitIdDropdownOptions: DropdownProps[],
}
export interface MasterChangeState extends BaseViewChangeSate {
    editingEntity: MasterHoldEntity,
}

export interface MasterHoldChgInfoChangeState extends BaseViewChangeSate {
    masterHoldChgInfoList: MasterHoldChargeInfoEntity[],
    selectedRows: MasterHoldChargeInfoEntity[]
}

export interface MasterHoldMaintenanceModel {
    isLoading: boolean,
    isShowDetail: boolean,
    isShowDeleteModal: boolean,
    tableData: MasterHoldEntity[],
    currentSelectedRow: MasterHoldEntity,
    selectedRows: MasterHoldEntity[],
    dynamicOptions: MasterHoldDropdownOptions,
    masterState: MasterChangeState,
    detailState: MasterHoldChgInfoChangeState,
    isBackMaster: boolean
    
}

export const EMPTY_MASTER_HOLD_MODEL: MasterHoldMaintenanceModel = {
    isLoading: false,
    isShowDetail: false,
    isShowDeleteModal: false,
    tableData: [],
    currentSelectedRow: { ...EMPTY_MASTER_HOLD_ENTITY },
    selectedRows: [],
    dynamicOptions: {
        masterCompanyCodeDropdownOptions: [],
        companyCodeDropdownOptions: [],
        chargeTypeDropdownOptions: [],
        subChargeTypeDropdownOptions: { },
        tariffTypeDropdownOptions: [],
        tariffCodeDropdownOptions: {},
        shipmentTypeDropdownOptions: [],
        targetStateDropdownOptions: [],
        cntrStatusDropdownOptions: [],
        modalityDropdownOptions: [],
        endVisitIdDropdownOptions: [],
    },
    masterState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        },
        editingEntity: EMPTY_MASTER_HOLD_ENTITY,
    },
    detailState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        },
        masterHoldChgInfoList: [],
        selectedRows: []
    },
    isBackMaster: false
}